import React, { useState } from 'react';
import axiosInstance from '../services/axiosConfig';
import './LandingPage.css';

function LandingPage() {
    const [correo_electronico, setCorreoElectronico] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    const handleLogin = async (event) => {
        event.preventDefault();
        try {
            const response = await axiosInstance.post('token/', {
                username: correo_electronico,
                password: password,
            });
            localStorage.setItem('authToken', response.data.access);
            window.location.href = '/plataforma';
        } catch (error) {
            setError('Correo electrónico o contraseña incorrectos');
        }
    };

    return (
        <div className="landing-container">
            <div className="left-panel">
                <img src={require('../assets/images/AF.svg').default} alt="Company Logo" className="large-logo"/>
                <h1 className="welcome-text">AF Peritaciones</h1>
                <p className="welcome-subtext">Plataforma de Gestión de Peritaciones Judiciales Forenses</p>
                <div className="partner-logos">
                    <img src={require('../assets/images/rics_logo.svg').default} alt="Junta de Andalucía"/>
                    <img src={require('../assets/images/logo.svg').default} alt="RICS"/>
                    <img src={require('../assets/images/icono.svg').default} alt="Aenor"/>
                </div>
            </div>
            <div className="right-panel">
                <div className="help-link">
                    <a href="mailto:aflores@afperitaciones.es">¿Necesitas ayuda?</a>
                </div>
                <div className="login-dialog">
                    <div className="security-icon-container">
                        <img
                            src={require('../assets/icons/green-lock.svg').default}
                            alt="Candado verde"
                            className="green-lock-icon"
                        />
                        <div className="security-tooltip">
                            <p>Esta plataforma está protegida y tus credenciales están seguras.</p>
                            <p>Recuerda que si fallas la contraseña 2 veces consecutivas, se bloqueará el acceso desde esta IP.</p>
                        </div>
                    </div>

                    <img src={require('../assets/images/AF.svg').default} alt="Company Logo" className="dialog-logo" />
                    <h2>Inicia sesión en tu cuenta</h2>
                    {error && <p className="error-message">{error}</p>}
                    <form onSubmit={handleLogin}>
                        <input
                            type="email"
                            placeholder="Correo electrónico"
                            value={correo_electronico}
                            onChange={(e) => setCorreoElectronico(e.target.value)}
                            required
                        />
                        <input
                            type="password"
                            placeholder="Contraseña"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <div className="form-options">
                            <a href="/password-reset-request/" className="forgot-password">¿Olvidaste tu contraseña?</a>
                        </div>
                        <button type="submit">Iniciar sesión</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default LandingPage;
